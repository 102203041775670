$(document).ready( function(){
  var changeStatus = $("[data-behavior='change-status']"),
      statusSelector = $("[data-structure='status-selector']");

  changeStatus.hover( function(){
    var element = $(this);
    clearTimeout(element.data("slideUpTimeout"));
    var slideDownTimeout = setTimeout( function(){
      statusSelector.slideDown();
    }, 200);
    element.data("slideDownTimeout", slideDownTimeout)
  }, function(){
    var element = $(this);
    clearTimeout(element.data("slideDownTimeout"));
    var slideUpTimeout = setTimeout( function(){
      statusSelector.slideUp();
    }, 200);
    element.data("slideUpTimeout", slideUpTimeout);
  });
});

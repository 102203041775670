
envs = {
    japan: {
        makeCallUri: "sip:9993373590@tropo-gw03.acrossway.net",
        apiUrl: 'https://jp.zomnio.com',
        firebaseUrl: "https://jp-zomnio-b8f62.firebaseio.com"
    },
  production: {
    makeCallUri: "9994065594@sip.tropo.com",
    apiUrl: 'https://www.zomnio.com',
    firebaseUrl: "https://agent-zomnio.firebaseio.com"
  },
  staging: {
    makeCallUri: "9993123736@sip.tropo.com",
    apiUrl: 'https://tropo-ivr-staging.herokuapp.com',
    firebaseUrl: "https://zomnio-staging.firebaseio.com"
  },
  test: {
    makeCallUri: "9994488508@sip.tropo.com",
        apiUrl: 'https://tropo-ivr-test.herokuapp.com',
    firebaseUrl: "https://zomnio-test.firebaseio.com"
  },
  development: {
    makeCallUri: "9993934981@sip.tropo.com",
    apiUrl: 'https://zomnio-dev.herokuapp.com',
    firebaseUrl: "https://zomnio-dev-61a98.firebaseio.com"
  },
  local: {
    // makeCallUri: "9995019107@sip.tropo.com",  // Jeff's
    makeCallUri: "sip:9993274770@sip.tropo.com", // Jeremy
    // makeCallUri: "9994065594@sip.tropo.com", // prod
    // apiUrl: 'https://www.zomnio.com', //prod
    // firebaseUrl: "https://agent-zomnio.firebaseio.com" // prod
    // makeCallUri: "sip:9990835522@sip.tropo.com", // Jai
        // makeCallUri: "9990790497@sip.tropo.com",
    // makeCallUri: "9994068430@sip.tropo.com", // Julian

    apiUrl: 'http://localhost:3000',
    // firebaseUrl: "https://zomnio-dev.firebaseio.com"
    firebaseUrl: "https://jeremy-zomnio.firebaseio.com"
    // firebaseUrl: "https://zomnio-dev-61a98.firebaseio.com"
    // firebaseUrl: "https://jai-dev.firebaseio.com" //Jai
    // firebaseUrl: "https://jeff-zom-agent.firebaseio.com"
    // firebaseUrl: "https://zomnioagentlocal.firebaseio.com/" // Julian
  }
}

var SIGNOUT_PATH = '/api/v1/auth/sign_out_as_agent';
var EMAIL_SIGN_IN_PATH = '/api/v1/auth/sign_in_as_agent';
var CURRENT_AGENT_PATH = '/api/v1/acd/current_agent';
var TOKEN_VALIDATION_PATH = '/api/v1/auth/validate_token';

function Zad() {
  this.env = envs[this._getEnvironment()];

  this.initialized = false;
  this.agent = null;
  this.calls = [];
  this.contacts = [];
  this.agents = [];
  this.showAddressBook = true;
  this.pendingCallData = null;
  this.pendingCallActions = [];
  this._clientId = this._guid();
  this._lastAgentUpdate = {};

  $.auth.configure({
    apiUrl:                this.env.apiUrl,
      signOutPath:           SIGNOUT_PATH,
      emailSignInPath:       EMAIL_SIGN_IN_PATH,
      tokenValidationPath:   TOKEN_VALIDATION_PATH,
      handleTokenValidationResponse: this._continueLogin.bind(this)
  });

  // Overrides j-tokers signOut method to force this to be synchronous
  // so it is always called on client close.
  // Code is directly copied from j-toker repo.
  // The only change is the async flag.
  // https://github.com/lynndylanhurley/j-toker/blob/master/src/j-toker.js
  $.auth.signOut = function(opts) {
    var SIGN_OUT_SUCCESS    = 'auth.signOut.success';
    var SIGN_OUT_ERROR        = 'auth.signOut.error';

    if (!opts) {
      opts = {};
    }

    var config     = this.getConfig(opts.config),
        signOutUrl = this.getApiUrl() + config.signOutPath,
        dfd        = $.Deferred();

    $.ajax({
      url: signOutUrl,
      context: this,
      method: 'DELETE',
      async:   false, // Make this synchronous

      success: function(resp) {
        this.resolvePromise(SIGN_OUT_SUCCESS, dfd, resp);
      },

      error: function(resp) {
        this.rejectPromise(
          SIGN_OUT_ERROR,
          dfd,
          resp,
          'Failed to sign out.'
        );
      },

      complete: function() {
        this.invalidateTokens();
      }
    });

    return dfd.promise();
  };
}

heir.inherit(Zad, EventEmitter);

function getParameterByName(name, url) {
    if (!url) {
      url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

Zad.prototype._getEnvironment = function() {
  var env = getParameterByName("env");
  if (env) {
    return env;
  }

  if (window.location.host == "agent.zomnio.com") {
      return "production";
  } else if (window.location.host == "jp-zomnio-b8f62.firebaseapp.com") {
      return "japan";
  } else if (window.location.host == "agent-staging.zomnio.com") {
      return "staging";
  } else if (window.location.host == "zomnio-test.firebaseapp.com") {
      return "test";
  } else if (window.location.host == "agent.zomn.io") {
      return "development";
  } else {
      return "local";
  }
};

Zad.prototype._getCallControlHandler = function(mediaType) {
  console.log("Getting call control handler for mediaType: " + mediaType);
  switch(mediaType) {
    case 'browser':
      return new OnSipCallControl(this);
    case 'cucm':
        return new JtapiCallControl(this);
    default:
        console.log("Returning onsip callcontrol");
        return new OnSipCallControl(this);
  }
};

Zad.prototype.login = function(loginInfo, callback)  {
  console.log("Loggin in");
  var self = this;

  self.pressedLogin = true;
  self._loginPending = true;

  var username = loginInfo.username;
  var password = loginInfo.password;

  self.debugUrl = null;
  if (window.LogRocket) {
    self.debugUrl = LogRocket.recordingURL;
  }

  $.auth.emailSignIn({
      email: username,
      password: password,
      extension: loginInfo.extension,
      media_type: loginInfo.mediaType,
      debug_url: self.debugUrl

    }).then(function(user) {
      self._continueLogin(user, callback);
  })
  .fail(function(resp) {
    console.error(resp.data.errors.join(' '));
    alert(resp.data.errors.join(' '));
    console.error('Authentication failure: ' + resp.data.errors.join(' '));
    callback('Authentication failure: ' + resp.data.errors.join(' '));

    self._loginPending = false;
  });
};

Zad.prototype._continueLogin = function(response, callback) {
  if (this.agent) {
    console.warn("Already logging in, ignoring continue login request.");
    return;
  }

  var self = this;
  self._loginPending = true;
  self.initialized = true;

  var agent = response.data;

  if (window.LogRocket) {
    LogRocket.identify(agent.uid, {
      name: agent.name,
      email: agent.email,
    });
  }

  self.extension = agent.extension;
  self.mediaType = agent.media_type;

  self.agent = new Agent({
      id: agent.id,
      name: agent.name,
      username: agent.uid,
      sipUri: agent.sip_uri,
      ciscoGatewayId: agent.cisco_gateway_id,
      skills: agent.skills || [],
      supervisor: agent.supervisor
    });

    self.organizationId = agent.organization_id;
    self.skills = agent.skills;

    console.log(agent);
    console.log('Welcome ' + agent.uid + '!');
    firebaseOrgUrl = self.env.firebaseUrl + "/organizations/" + self.organizationId;
    //self.env.firebaseOrgUrl = firebaseOrgUrl;
    self.firebaseRef = new Firebase(self.env.firebaseUrl);
    self.firebaseOrgRef = self.firebaseRef.child("organizations/" + self.organizationId);

    self.agentsRef = self.firebaseOrgRef.child("agents");
    self.agentRef = self.agentsRef.child(self.agent.id);
    self.activeCallsRef = self.agentRef.child("active_calls")
    self.personalStatsRef = self.agentRef.child("stats");
    self.contactsRef = self.firebaseOrgRef.child("contacts");
    self.skillStatsRef = self.firebaseOrgRef.child("skill_stats");
    self.loginsRef = self.firebaseOrgRef.child("logins/" + self.agent.id);
    if(self.mediaType == "cucm") {
      self.cucmRef = new Firebase(firebaseOrgUrl + "/cucm/" + self.extension);
    }

    if (!self._debugUrl && window.LogRocket && LogRocket.recordingURL) {
      self._debugUrl = LogRocket.recordingURL;
      // Not implemented server side yet.
      //self._logToServer( { debugUrl: self._debugUrl });
    }

    Firebase.enableLogging(true);
    Firebase.goOnline();

    self.agentsRef.authWithCustomToken(agent.auth_token, function(error, authData) {
      if (error) {
        console.error("Login Failed!", error);
        if (callback)
          callback(error);
      } else {
        self.firebaseRef.child(".info/connected").on('value', function(connectedSnap) {
          if (connectedSnap.val() === true) {
            console.log("Firebase connected.");
            self.emit('reconnected');
          } else {
            console.log("Firebase disconnected.");

            if (!self.initialized) {
              return;
            }

            console.error("Firebase disconnected unexpectedly.");
            self.emit('disconnected');
          }
        });

        self.agentsRef.child(self.agent.id).child("client_session").once("value", function (snapshot) {
            var currentSession = snapshot.val();
            var disc;
            if(currentSession === null || currentSession == self._clientId) {
                disc = true;
            }
            else {
                disc = confirm("This agent appears to be logged in to the call center in another web browser or browser tab.  This will disconnect the other browser session and drop any calls currently in progress for this agent.  Are you sure?");
            }
            if(disc){
                var currentSession = snapshot.val();

                console.log("Received client session.");
                console.log(currentSession);

                self.agentsRef.child(self.agent.id).child("client_session").set(self._clientId);
                self.agentsRef.child(self.agent.id).child("client_session").onDisconnect().set(null);

                if(self.mediaType == "cucm") {
                    self._cucm_login(agent);
                } else {
                    self._completeLogin();
                    self.emit('login', agent);
                }
                if (callback) {
                    callback(null, agent);
                }
            }
            else{
                self._disconnect();
            }
        });
      }
    });
};

Zad.prototype._cucm_login = function(agent) {
  var self = this;

  self._pushPhoneCallControlCommandToServer("LOGIN");

  self.cucmRef.on("value", function(snapshot) {
      if(!snapshot.val()) {
        return;
      }

      var cucmChange = snapshot.val().event;
      if(cucmChange == "LOGGED_IN") {
        if(self.cucmLogInTimeout) {
          window.clearTimeout(self.cucmLogInTimeout);
        }
        console.log("Removing event at path: " + self.cucmRef.child("event"));
        self.cucmRef.child("event").remove();
        self.cucmRef.off();
        self.logged_into_jtapi = true;
        self._completeLogin();
        self.emit('login', agent);
      }
      else if(cucmChange == "LOGIN_FAILED"){
        self.logged_into_jtapi = false;
        console.error("Failed to log into jtapi gate way for following reason: " + snapshot.val().reason);
        if(self.pressedLogin) {
          alert('Failed To Log In To Jtapi Gateway');
        }
      }

  });
  self.cucmLogInTimeout = setTimeout(function () {
    if (!self.logged_into_jtapi) {
      if(self.pressedLogin) {
        alert('Failed To Log In To Jtapi Gateway');
      }
    }
  }, 5000);

}

Zad.prototype.findCallBySid = function(sid) {
  console.log("Finding call by sid: " + sid, zad.calls);
  for(var i =0; i < this.calls.length; i++) {
    if(this.calls[i].tropoSessionId === sid) {
      return this.calls[i];
    }
  }
  return false;
}

Zad.prototype._completeLogin = function() {
  var self = this;

  this._pushStateToServer('NOT_READY', true);

  this._callControl = this._getCallControlHandler(this.mediaType);
  this._callControl.env = this.env;
  this.muteSupported = this._callControl.muteSupported;

  console.log("Requestion permission for Notifications...");
  Notification.requestPermission();

    this._agentStatsListener = function(){
      if(self.skillStatsRef !== ""){
          return (
              self.skillStatsRef.on("value", function(snapshot){
                  console.log("Received skill stats update:", snapshot.val());
                  var stats = {};
                  if(snapshot.exists() && self.agent){
                      $.each(self.agent.skills, function(skillKey, skillValue){
                          if(skillValue !== undefined){
                              stats[skillKey] = {};
                              var lht_date = new Date(null);
                              var act_date = new Date(null);
                              stats[skillKey]['cht'] = snapshot.val()[skillKey]['Calls Handled Today'];
                              stats[skillKey]['coh'] = snapshot.val()[skillKey]['Calls On Hold'];
                              act_date.setSeconds(snapshot.val()[skillKey]['Average Call Time']);
                              if(!isNaN(act_date.getTime())){
                                  stats[skillKey]['act'] = act_date.toISOString().substr(11,8);
                              }
                              else{
                                  stats[skillKey]['act'] = 0;
                              }
                              lht_date.setSeconds(snapshot.val()[skillKey]['Longest Hold Time']);
                              if(!isNaN(lht_date.getTime())){
                                  stats[skillKey]['lht'] = lht_date.toISOString().substr(11,8);
                              }
                              else{
                                  stats[skillKey]['lht'] = 0;
                              }
                          }
                      });
                  }
                  else{
                      $.each(self.agent.skills, function(skillKey, skillValue){
                          stats[skillKey] = {};
                          stats[skillKey]['cht'] = 0;
                          stats[skillKey]['coh'] = 0;
                          stats[skillKey]['act'] = 0;
                          stats[skillKey]['lht'] = 0;
                      });
                  }
                  console.log("Updated stats for current agent:", stats);
                  if(self.agent){
                      self.emit("stats update", stats, self.agent.skills);
                  }
              }, function(error){
                  console.log("oops" + error.code);
              }));
      }
  }();

  self.activeCallsRef.on('value', function(snapshot) {
    console.log("Active Calls Snapshot val: ", snapshot.val());
    if(!snapshot.val()) {
      return;
    }

    if(zad.monitoredAgent) {

      var sid = Object.keys(snapshot.val())[0]
      var call = snapshot.val()[sid]

      zad.calls.push({
        direction: "outbound",
        to: call.to,
        current: true,
        held: false,
        connected: true,
        index: 1,
        otherParty: call.to,
        sipSession: zad.monitoredAgent.sipSession
      });
      zad.monitoredAgent = null;
      self.emit("call update");
      return;
    }

    var sids = Object.keys(snapshot.val())
    for(var i = 0; i < sids.length; i++) {
      var sid = sids[i]
      var call = snapshot.val()[sid]
      if(call.status === "connected") {
        var currentCall = zad.findCallBySid(sid)
        currentCall.connected = true
      }
    }

    self.emit("outbound call answer")

  });





  self.personalStatsRef.on("value", function(snapshot) {
    if(!snapshot.val() || !self.agent) {
      return;
    }

    console.log("Updating agent stats");

    var stats = {};
    stats['cht'] = snapshot.val()['Calls Handled Today'];
    stats['coh'] = snapshot.val()['Calls On Hold'];

    var act_date = new Date(null);
    act_date.setSeconds(snapshot.val()['Average Call Time']);
    if(!isNaN(act_date.getTime())){
        stats['act'] = act_date.toISOString().substr(11,8);
    }
    else{
        stats['act'] = 0;
    }

    var lht_date = new Date(null);
    lht_date.setSeconds(snapshot.val()['Longest Hold Time']);
    if(!isNaN(lht_date.getTime())){
        stats['lht'] = lht_date.toISOString().substr(11,8);
    }
    else{
        stats['lht'] = 0;
    }

    self.emit("stats personal update", stats);
  });



  this.loadAddressBook();
  this.teamMemberChangeListener = self.agentsRef.on("value", function(snapshot) {
      if (!self.agent)
        return;

      var data = [];
      snapshot.forEach(function(childSnapshot) {
          var key = childSnapshot.key();
          var childData = childSnapshot.val();

          // Only interested in other agents here.
      if (key == self.agent.id)
        return;

        data.push(childData);
      });
      self.agents = data;
      if(self.monitoredAgent) {
        var agent_id = self.monitoredAgent.id;
        var agent = self._getAgentById(agent_id);
        self.monitoredAgent.state = agent.state;
        self.monitoredAgent.pending_state = agent.pending_state;
      }
      self.emit('team member change');
  });
    this.teamMemberRemovedListener = self.agentsRef.on("child_removed", function(oldChildSnapshot, prevChildKey){
        self.emit('team member remove', oldChildSnapshot.name());
    });

    this.contactChangeListener = self.contactsRef.on("value", function(snapshot){
        if(snapshot){
            self.contacts = [];
            snapshot.forEach(function(childSnapshot){
                self.contacts.push(childSnapshot.val());
            })
            self.emit('team member change');
        }
    })();

    this.agentChangeListener = self.agentsRef.child(self.agent.id).on("value", function(snapshot) {
      if (!snapshot.val()) {
        return;
      }

      var agentInfo = snapshot.val();
      console.log("Received agent change: ", agentInfo);

      self._lastAgentUpdate = agentInfo;
      if (agentInfo.command)
        return;

      if (agentInfo.client_session && agentInfo.client_session != self._clientId) {
        // Another session has logged in for this agent.
        self._disconnect();
        return;
      }

      if (agentInfo.outbound_call_tropo_session_id && self.calls.length > 0) {
        self.calls[self.calls.length-1].tropoSessionId = agentInfo.outbound_call_tropo_session_id;
      }

      if (!self._loginPending && agentInfo.state == "LOGOUT") {
              self._disconnect();
      } else if (self._loginPending == true) {

        self.agent.state = agentInfo.state;
        self.agent.pendingState = agentInfo.pending_state;

        self._loginPending = false;
      } else {
        self._onStateChange(agentInfo);
     }

      console.log("End of agent change");
      console.log("Current calls:", JSON.stringify(self.calls, calls_replacer));
  });


    if(self.mediaType == "cucm") {
        self.cucmChangeListner = self.cucmRef.on("value", function(snapshot) {
          if(!snapshot.val()) {
            return;
          }
            var cucmChange = snapshot.val().event;
            var cucmChangeProperties = snapshot.val().event_properties;
            console.log("Handling cucm change: " + cucmChange);
            console.log("With properties: ");
            console.log(cucmChangeProperties);
            self._onCucmChange(cucmChange, cucmChangeProperties);
        });
    }

}

Zad.prototype.loadAddressBook= function(){
    this._getAgentList();
    this._getContactList();
};
Zad.prototype._getContactList = function(){
    var self = this;
    this.contactsRef.once("value", function(snapshot){
        self.contacts = [];
        snapshot.forEach(function(childSnapshot){
            var childData = childSnapshot.val();
            self.contacts.push(childData);
        });
        self.emit('team member change');
    });
};
Zad.prototype._getAgentList = function() {
    var self = this;
    this.agentsRef.once("value", function(snapshot){
        self.agents = [];
        snapshot.forEach(function(childSnapshot) {
            var key = childSnapshot.key();
            var childData = childSnapshot.val();

            // Only interested in other agents here.
            if (key == self.agent.id)
                return;

            self.agents.push(childData);
        });
        self.emit('team member change');
    });
};

Zad.prototype._disconnect = function() {
    if (!this.initialized) {
      return;
    }

    this.initialized = false;

    if(this._callControl){
        this._callControl.shutdown();
    }

    this._loginPending = false;
    this._clientId = this._guid();
    this.agentsRef.child(this.agent.id).off("value", this.agentChangeListener);
    this.contactsRef.off("value", this.contactsChangeListener);
    this.agentsRef.off("value", this.teamMemberChangeListener);
    this.skillStatsRef.off("value", this.agentStatsListener);
    this.firebaseRef.child(".info/connected").off("value");

    if(this.mediaType == "cucm") {
      this.cucmRef.off();
    }

    this.agentsRef.unauth();
    Firebase.goOffline();

    delete this._lastAgentUpdate;
    delete this.agent;
    delete this.organizationId;
    delete this.skills;
    delete this.agentsRef;
    delete this.skillStatsRef;
    delete this.firebaseRef;

    this.emit("logout");
}

Zad.prototype._onCucmChange = function(cucmChange, cucmChangeProperties) {
    switch(cucmChange) {
        case "LOGGED_IN":
          console.log("Removing event at path: " + this.cucmRef.child("event"));
          this.cucmRef.child("event").remove();
          break;
        case "RINGING":
            console.log("cucm phone is ringing");
            this._handleCucmRingingChange(cucmChangeProperties);
            break;
        case "OFFHOOK":
            console.log("cucm phone is offhook");
            this._handleCucmOffHookChange(cucmChangeProperties);
            break;
        case "DIALING":
            console.log("cucm phone is dialing");
            this._handleCucmDialingChange(cucmChangeProperties);
            break;
        case "CONNECTED":
            console.log("cucm phone has active connection");
            this._handleCucmConnectedChange(cucmChangeProperties);
            break;
        case "DISCONNECTED":
            console.log("cucm phone call has been disconnected");
            this._handleCucmDisconnectedChange(cucmChangeProperties);
            break;
        default:
            console.error("Invalid cucm Change.." + cucmChange);
    }
};

Zad.prototype._handleCucmRingingChange = function(properties) {

    var call =  {
        zomnioCallId: properties.zomnio_call_id,
        direction: properties.direction,
        connected: false,
        index: this._activeCallCount() + 1,
        from: properties.callerId,
        data: this.pendingCallData
    };

    this.pendingCallData = null;
    this.calls.push(call);
    this.setCurrentCall(call.index);


    if(properties.direction === "inbound") {
      call.otherParty = properties.callerId
      this.emit('inbound call begin', call);
    } else if (properties.direction === "outbound") {
      call.otherParty = properties.calledId
      this.emit('outbound call begin', call);
    } else {
      console.error("Unrecognized call direction: " + properties.direction);
    }
}

Zad.prototype._handleCucmOffHookChange = function(properties) {
  var call = {
        id: properties.zomnio_call_id,
        zomnioCallId: properties.zomnio_call_id,
        to: properties.calledId,
        otherParty: properties.calledId,
        direction: 'outbound',
        muted: false,
        held: false,
        index: this._activeCallCount() + 1,
        connected: false
      }
  this.calls.push(call);
  this.setCurrentCall(call.index);
  this.emit('phone offhook', call);
}

Zad.prototype._handleCucmDialingChange = function(properties) {
  var call = this._getCallByZomnioCallId(properties.zomnio_call_id)
  call.to = properties.calledId;
  call.otherParty = properties.calledId;
  this.emit('outbound call begin', call);
}

Zad.prototype._handleCucmConnectedChange = function(properties) {
  var call = this._getCallByZomnioCallId(properties.zomnio_call_id);
  call.connected = true;
  if(properties.direction === "inbound") {
    this.emit('inbound call answer', call);
  } else if (properties.direction === "outbound") {
    call.otherParty = properties.calledId;
    call.to = properties.calledId;
    this.emit('outbound call answer', call);
  } else {
    console.error("Unrecognized call direction: " + properties.direction);
  }
}

Zad.prototype._handleCucmDisconnectedChange = function(properties) {
  var call = this._getCallByZomnioCallId(properties.zomnio_call_id);
  this._onCallEnd(call);
}


Zad.prototype._activeCallCount = function() {
    return this.calls.length;
}

Zad.prototype._getAgentById = function(agentId) {
  console.log("Getting agent by id: " + agentId);
  for (var i = 0; i < this.agents.length; i++) {
    if (this.agents[i].id == agentId) {
        return this.agents[i];
    }
  }
  return false;
}


Zad.prototype._onStateChange = function(agentInfo) {
  var self = this;

  console.log("State update received from server:", agentInfo);
  console.log("Current calls:", JSON.stringify(self.calls, calls_replacer));
  self.agent.state = agentInfo.state;
  self.agent.pendingState = agentInfo.pending_state;

  if (agentInfo.state == 'RESERVED') {
    // This means we either just received a call or are about to receive one.
    // Need to store screen pop data for the call in either case.

    var callerId = agentInfo.active_caller_id;
    var tropoSessionId = agentInfo.tropo_session_id;

      var callRef = self.firebaseOrgRef.child("calls/" + tropoSessionId);
      // Quick hack for testing purposes.
      // Allows us to create a global call ref object that we can push
      // simulatd updates through.
      if (typeof window.testCallRef !== 'undefined') {
        callRef = window.testCallRef;
      }

      self._pendingReservationCall =  {
          id: tropoSessionId,
          sipSession: null,
          tropoSessionId: tropoSessionId,
          direction: 'inbound',
          connected: false,
          held: false,
          muted: false,
          index: self.calls.length + 1,
          from: callerId,
          otherParty: callerId,
          data: null
        };

      callRef.once("value", function(snapshot) {
        console.log("RECEIVED CALL UPDATE!!!!!!!!!!!!!")
        var callData = snapshot.val();
        if (!callData) {
          console.log("NO CALL DATA!!!!!!");
          return;
        }
        console.log("THERE IS CALL DATA!@!!!!!");
          var callActions = callData.actions;
          delete callData.actions;

          console.log("CallData:");
          console.log(callData);

          var call = {};
          if (self.calls[0]) {
            console.log("SETTING CALL DATA!!!!!");
            self.calls[0].from = callerId;
            self.calls[0].otherParty = callerId;
            self.calls[0].data = callData;
            self.calls[0].actions= callActions;
            self.calls[0].tropoSessionId;

            call = self.calls[0];
          } else {
            self._pendingReservationCall.data = callData;
            self._pendingReservationCall.actions = callActions;
            self.pendingCallData = callData;
            self.pendingCallActions = callActions;

            call = self._pendingReservationCall;
          }

          if (callActions && callActions.agent_datum_id) {
            var actionBody = {
              agent_datum_id: callActions.agent_datum_id,
              agent: self.agent.id,
              tropo_session_id: call.tropoSessionId
            };

            $.ajax({
                  method:  "POST",
                  url:     self.env.apiUrl + "/agent_data_actions",
                  data:    actionBody,
                  async:   true,
                  error: function(jqXHR, textStatus, errorThrown){
                      console.error(errorThrown + ": " + textStatus);

                   //    if(callData.screen_pop_url != "") {
                    //  window.open(callData.screen_pop_url,'zscreenpop');
                    // }
                  }
              }).done(function() {
                  // if(callData.screen_pop_url != "") {
                  //  window.open(callData.screen_pop_url,'zscreenpop');
                  // }
              });

        } else {
          // if(callData.screen_pop_url != "") {
        //           window.open(callData.screen_pop_url,'zscreenpop');
        //       }
        }

          self.emit('call data update', call);
      });

  }

  self.emit('agent change', self.agent);
};

Zad.prototype.ready = function()  {
    console.log("Running ready...");
    if (this.agent.state == "READY") {
        console.warn("Invalid state change from", this.agent.state, "to", "READY");
        return;
    }

    if(this.agent.state == "TALKING") {
      this._pushPendingStateToServer("READY");
      this.agent.pendingState = "READY";
      this.emit('pending state change', this.agent);
      return;
    }

    this._pushStateToServer("READY");
}

Zad.prototype.notReady = function()  {
    console.log("Running not ready...");
    if (this.agent.state == "NOT_READY") {
        console.warn("Invalid state change from", this.agent.state, "to", "NOT_READY");
        return;
    }

    if(this.agent.state == "TALKING") {
      this._pushPendingStateToServer("NOT_READY");
      this.agent.pendingState = "NOT_READY";
      this.emit('pending state change', this.agent);
      return;
    }

    this._pushStateToServer("NOT_READY");
}

Zad.prototype.silentMonitor = function(agent) {

    if(zad.monitoredAgent && zad.monitoredAgent.id === agent.id) {
      return;
    }
    else if(zad.monitoredAgent) {
      this.endMonitor();
    }

    if(this.mediaType === "browser") {
      this._callControl.silentMonitor(agent);
    } else if (this.mediaType === "mobile"){

    } else {

    }

    this._pushStateToServer("TALKING");
}

Zad.prototype.endMonitor = function() {
  console.log("Ending monitor session");

  this._callControl.endMonitor();
}

Zad.prototype.bargeIn = function() {
  console.log("Barging in");

  var monitoredAgent = this.monitoredAgent;

  if(this.mediaType === "browser") {
    this._callControl.bargeIn(monitoredAgent);
  } else if (this.mediaType === "mobile"){
  } else {
  }

}

Zad.prototype.answer = function(callIndex)  {
  callIndex = callIndex || this.calls.length;

  console.log("Zad Answering...");
  console.log("Current calls:", JSON.stringify(this.calls, calls_replacer));

  if (this.mediaType == "browser"){
      this._callControl.answer(callIndex);

    } else if (this.mediaType == "mobile"){

    } else {
      this._pushPhoneCallControlCommandToServer("ANSWER", {zomnioCallId: this._getCallByIndex(callIndex).zomnioCallId});
    }
}

Zad.prototype.makeCall = function(dial)  {
  var self = this;
  console.log("Zad Make Call...");
  console.log(JSON.stringify(self.calls, calls_replacer));

  return this._callControl.makeCall(dial);
}

Zad.prototype.quickTransfer = function(call, number) {
  console.log("Zad quick transfer..");

  return this._callControl.quickTransfer(call, number);
}

Zad.prototype.toggleHold = function(callIndex)  {
  if (this.calls[0].held) {
      this.resume(callIndex);
    } else {
      this.hold(callIndex)
  }
  console.log("Zad toggle hold...");
  console.log("Current calls:", JSON.stringify(this.calls, calls_replacer));
}

Zad.prototype.hold = function(callIndex)  {
  this._callControl.hold(callIndex);

  console.log("Zad hold...");
  console.log("Current calls:", JSON.stringify(this.calls, calls_replacer));

  this._getCallByIndex(callIndex).held = true;

}


Zad.prototype.resume = function(callIndex)  {
  this._callControl.resume(callIndex);
  this._getCallByIndex(callIndex).held = false;
  console.log("Zad resume...");
  console.log("Current calls:", JSON.stringify(this.calls, calls_replacer));
}

Zad.prototype.toggleMute = function(callIndex)  {
  if (this._getCallByIndex(callIndex).muted) {
    this.unmute(callIndex);
    } else {
      this.mute(callIndex);
    }
    console.log("Zad toggle mute...");
  console.log("Current calls:", JSON.stringify(this.calls, calls_replacer));
}

Zad.prototype.mute = function(callIndex)  {
  this._callControl.mute(callIndex);
  this._getCallByIndex(callIndex).muted = true;
  console.log("Zad mute...");
  console.log("Current calls:", JSON.stringify(this.calls, calls_replacer));
}

Zad.prototype.unmute = function(callIndex)  {
  this._callControl.unmute(callIndex);
  this._getCallByIndex(callIndex).muted = false;
  console.log("Zad unmute...");
  console.log("Current calls:", JSON.stringify(this.calls, calls_replacer));
}

Zad.prototype.hangup = function(callIndex)  {
  this._callControl.hangup(callIndex);
  console.log("Zad hangup...");
  console.log("Current calls:", JSON.stringify(this.calls, calls_replacer));
}

Zad.prototype.conference = function(callIndex1, callIndex2)  {
  this._callControl.conference(callIndex1, callIndex2);
  console.log("Zad conference");
  console.log("Current calls:", JSON.stringify(this.calls, calls_replacer));
}

Zad.prototype.transfer = function(callIndex1, callIndex2)  {
  this._callControl.transfer(callIndex1, callIndex2);
  console.log("Zad transfer...");
  console.log("Current calls:", JSON.stringify(this.calls, calls_replacer));
}

Zad.prototype.logout = function()  {
    if (!this.agent.state ||
          (this.agent.state != "READY" &&
            this.agent.state != "NOT_READY")) {
        console.warn("Invalid logout from", this.agent.state);
        return;
    }

    console.log(this);
    this._callControl.shutdown();
    this.logged_into_jtapi = false;

    this.agentsRef.child(this.agent.id).child("client_session").set(null);
    $.auth.signOut();
}

Zad.prototype.closeClient = function()  {
  if (!this.agent) {
    return;
  }

  console.warn("Client closed, logging out.");
  if(this._callControl){
    this._callControl.shutdown();
  }
    //this._callControl.shutdown();
  $.auth.signOut();
  //this._disconnect();
}

Zad.prototype._onCallEnd = function(call) {
  console.log("Running _onCallEnd for call:", call);

  if(zad.agent.state === "RESERVED" && call.rejected) {
    this._pushStateToServer("NOT_READY");
  } else if(zad.agent.state === "RESERVED") {
    this._pushStateToServer("READY");
  }

  this._deleteCall(call.index)
  delete this._pendingReservationCall;

  this._callEndedSoUpdateIndexes();
  if (this.calls.length == 1) {
    this.setCurrentCall(this.calls[0].index);
  }

  this.emit('call end', call);
}

Zad.prototype._callEndedSoUpdateIndexes = function() {
  console.log("Updating call indexes.");
  for(var i = 0; i < this.calls.length; i++) {
    this.calls[i].index = i + 1;
  }
}

Zad.prototype._stateSynced = function(err) {
  if (err) {
      console.log('Synchronization failed');
      this.agentsRef.child(this.agent.id).off("value", this.agentChangeListener);
    }

    console.log('State update synced to server:', this.agent.state);
    this.emit('agent change', this.agent);
    console.log("Zad state synched...");
  console.log("Current calls:", JSON.stringify(this.calls, calls_replacer));
}

Zad.prototype._pushCallControlCommandToServer = function(command, async) {
  var self = this;

    var jqxhr = $.ajax({
      url: self.env.apiUrl + "/api/v1/acd/process_command?agent_id=" + self.agent.id + "&command=" + command,
      method: 'POST',
      async: false});
}

Zad.prototype._pushPhoneCallControlCommandToServer = function(command, parameters) {
    parameters = parameters || {};
    console.log("pushing call control command: " + command);

    var url = this.env.apiUrl + "/api/v1/phones/process_command"

    parameters.command = command;
    parameters.agent_id = this.agent.id;
    var encoded_params = encode_into_url_params(parameters)

    var jqxhr = $.ajax({
      url: url + "?" + encoded_params,
      method: 'POST',
      async: false});
}

Zad.prototype._pushStateToServer = function(state, sync) {
  console.log("Pushing State to Server: " + state);
  var self = this;
    sync = sync || false;

    var jqxhr = $.ajax({
      url: self.env.apiUrl + "/api/v1/acd/update_status?agent_id=" + self.agent.id + "&status=" + state,
      method: 'POST',
      async: !sync});
}

Zad.prototype._pushPendingStateToServer = function(pending_state, sync) {
  console.log("Pushing Pending State to Server: " + pending_state);
  var self = this;
  sync = sync || false;

  var jqxhr = $.ajax({
      url: self.env.apiUrl + "/api/v1/acd/update_pending_status?agent_id=" + self.agent.id + "&pending_status=" + pending_state,
      method: 'POST',
      async: !sync});
}

Zad.prototype._logToServer = function(logMessage, sync) {
  console.log("Pushing Log message to Server: " + logMessage);
  var self = this;
  sync = sync || false;

  var jqxhr = $.ajax({
      url: self.env.apiUrl + "/api/v1/acd/log?agent_id=" + self.agent.id,
      data: logMessage,
      method: 'POST',
      async: !sync});
}

Zad.prototype._getCallIndex = function(id) {
  for (var i=0; i < this.calls.length; i++){
    if (this.calls[i].id == id) {
        return this.calls[i].index;
      }
    }
    return false;
}

Zad.prototype._deleteCall = function(callIndex) {
  console.log("Deleting call with index: " + callIndex);
  var indexToDelete = -1;
  for (var i = 0; i < this.calls.length; i++) {
    if (this.calls[i].index == callIndex) {
      indexToDelete = i;
    }
  }

  if (indexToDelete >= 0)
    this.calls.splice(indexToDelete, 1);
}

Zad.prototype._guid = function() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}

// TODO move these into separate files and import via require

// TODO not sure we need actual inheritance here
//function CallControl(zad) { this.zad = zad; }
//heir.inherit(Agent, EventEmitter);


function JtapiCallControl(zad) {
    this.zad = zad;
    this.muteSupported = false;
}

JtapiCallControl.prototype.makeCall = function(destination) {
  if(this.zad.calls.length > 0 && this.zad.calls[0].connected) {
    var zomnioCallId = this.zad.calls[0].zomnioCallId;
    this.zad.calls[0].held = true;
    var params = {
      zomnioCallId: zomnioCallId,
      destination: destination
    };
    this.zad._pushPhoneCallControlCommandToServer("CONSULT", params);
  }
  else {
    var params = {
      destination: destination
    };
    this.zad._pushPhoneCallControlCommandToServer("MAKECALL", params);
  }

};

JtapiCallControl.prototype.hangup = function(callIndex) {
  console.log("JtapiCallControl running hangup for callIndex: " + callIndex);
  var call = this._getCallByIndex(callIndex);
  call.connected = false;
  var params = {
    zomnioCallId: call.zomnioCallId
  };
  this.zad._pushPhoneCallControlCommandToServer("HANGUP", params);
}

JtapiCallControl.prototype.hold = function(callIndex) {
  var call = this._getCallByIndex(callIndex);
  call.held = true;
  var params = {
    zomnioCallId: call.zomnioCallId
  };
  this.zad._pushPhoneCallControlCommandToServer("HOLD", params);
}

JtapiCallControl.prototype.resume = function(callIndex) {
  var call = this._getCallByIndex(callIndex);
  call.held = false;
  var params = {
    zomnioCallId: call.zomnioCallId
  };
  this.zad._pushPhoneCallControlCommandToServer("RESUME", params);
}

JtapiCallControl.prototype.transfer = function() {
  if(this.zad.calls.length == 2) {
    var heldCallZomnioId = this.zad.calls[0].zomnioCallId;
    var activeCallZomnioId = this.zad.calls[1].zomnioCallId;
    var params = {
      heldZomnioCallId: heldCallZomnioId,
      activeCallZomnioId: activeCallZomnioId
    };
    this.zad._pushPhoneCallControlCommandToServer("TRANSFER", params);
  }
}

JtapiCallControl.prototype.conference = function() {
  if(this.zad.calls.length == 2) {
    var heldCallZomnioId = this.zad.calls[0].zomnioCallId;
    var activeCallZomnioId = this.zad.calls[1].zomnioCallId;
    var params = {
      heldZomnioCallId: heldCallZomnioId,
      activeCallZomnioId: activeCallZomnioId
    };
    this.zad._pushPhoneCallControlCommandToServer("CONFERENCE", params);
  }
}

JtapiCallControl.prototype.shutdown = function() {
  return;
}

JtapiCallControl.prototype._getCallByIndex = function(index) {
  for (var i = 0; i < this.zad.calls.length; i++) {
    if (this.zad.calls[i].index == index) {
        return this.zad.calls[i];
      }
    }
    return null;
}


Zad.prototype._getCallByIndex = function(index) {
  for (var i = 0; i < this.calls.length; i++) {
    if (this.calls[i].index == index) {
        return this.calls[i];
      }
    }
    return null;
}


Zad.prototype.setCurrentCall = function(index) {
  console.log("Attempting to set current call: " + index);
  for (var i = 0; i < this.calls.length; i++) {
    var call = this.calls[i];
    if(call.index == index) {
      console.log("Setting current call: " + call.index);
      call.current = true;
    } else {
      console.log("UNsetting current call: " + call.index);
      call.current = false;
    }
  }
}


Zad.prototype._getCallByZomnioCallId = function(zomnioCallId) {
  for (var i = 0; i < this.calls.length; i++) {
    if (this.calls[i].zomnioCallId == zomnioCallId) {
        return this.calls[i];
      }
    }
    return false;
}


function OnSipCallControl(zad) {
  var self = this;

  console.log("Initializing OnSipCallControl");

  this.zad = zad;
  this.muteSupported = true;

  this.ua = new SIP.UA({
    uri: zad.agent.sipUri,
    traceSip: true
  });

  this.ua.on('invite', function (session) {
      console.log("Received invite:");
      console.log(session.request.data);
      console.log(session.request);

      if(session.request.headers['Monitoring']) {
        self.handleMonitorSession(session);
        return;
      }

      curr_sess = session;
      var notificationCallerID = "UNKNOWN"
      if(session.request.headers['X-Agent-Name']){
          var from = session.request.headers['X-Agent-Name'][0].raw;
          notificationCallerID = session.request.headers['X-Agent-Name'][0].raw;
      }
      else if(session.request.headers['X-Calling-Number']) {
          from = session.request.headers['X-Calling-Number'][0].raw;
          notificationCallerID = session.request.headers['X-Calling-Number'][0].raw;
      }
      else{
          from = null;
      }

      if (document.hidden) {
        var options = {
          icon: 'images/logo_blueclear_cloud_only.png',
          body: "Call From: " + notificationCallerID
        }
        var n = new Notification("Zomnio", options);
        setTimeout(n.close.bind(n), 5000);
      }


        var call =  {
          id: session.id,
          sipSession: session,
          direction: 'inbound',
          connected: false,
          index: self._activeCallCount() + 1,
          from: from,
          otherParty: from
        };
        if (self.zad._pendingReservationCall) {
          call = self.zad._pendingReservationCall;
          call.id = session.id;
          call.sipSession = session;

          delete self.zad._pendingReservationCall;
        }
        self.zad.calls.push(call);
        self.zad.setCurrentCall(call.index);

        if (self.zad.agent.state != "RESERVED") {
          self.zad._pushStateToServer("RINGING");
        }

      session.on('bye', function (session1) {
          console.log('SIP session ended.');
          self._onCallEnd(call);
        });

      session.on('rejected', function (session1) {
          console.log('SIP session rejected.');
          call.rejected = true;
          self._onCallEnd(call);
        });

      session.on('accepted', function (session1) {
          console.log('SIP session accepted.');
          if(call.direction === "inbound") {
            call.connected = true;
          }

          self.zad._pushStateToServer("TALKING");

          self.zad.emit('inbound call answer', call);
      });

        session.on('cancel', function (session1) {
          console.log('SIP session canceled.');
          try {
            session1.bye();
          } catch(error) {}
          // call.canceled = true;
          // self._onCallEnd(call);
        });

        self.zad.emit('inbound call begin', call);
    });
  console.log("OnSip Initialized.");
}
//heir.inherit(OnSipCallControl, CallControl);

OnSipCallControl.prototype.handleMonitorSession = function(sipSession) {
  console.log("Handling monitor session");
  var self = this;

  var monitored_agent_id = sipSession.request.headers['Monitored-Agent-Id'][0].raw;
  var monitored_agent = this._getAgentById(monitored_agent_id)

  this.zad.monitoredAgent = monitored_agent;
  this.zad.monitoredAgent.startTime = new Date();
  this.zad.monitoredAgent.sipSession = sipSession;


  sipSession.on('bye', function (session1) {
    console.log('Monitor SIP session ended.');
    self._onMonitorCallEnd();
  });
  sipSession.on('rejected', function (session1) {
    console.log('Monitor SIP session rejected.');
    self._onMonitorCallEnd();
  });
  sipSession.on('cancel', function (session1) {
    console.log('Monitor SIP session canceled.');
    self._onMonitorCallEnd();
  });
  sipSession.on('accepted', function (session1) {
    console.log('Monitor SIP session accepted.');

    self.zad.emit('monitor begin');
  });



  console.log("Accepting session");
  sipSession.accept({
      media: {
        constraints: {
            audio: true,
            video: false
        },
        render: {
          remote: document.getElementById('remoteAudio1'),
          local: document.getElementById('localAudio'),
        }
      }
  });

}

OnSipCallControl.prototype._getCallIndex = function(sessionId) {
  for (var i = 0; i < this.zad.calls.length; i++) {
    if (this.zad.calls[i].sipSession.id == sessionId) {
        return this.zad.calls[i].index;
      }
    }
    return false;
}

OnSipCallControl.prototype._getAgentById = function(agentId) {
  console.log("Getting agent by id: " + agentId);
  for (var i = 0; i < this.zad.agents.length; i++) {
    if (this.zad.agents[i].id == agentId) {
        return this.zad.agents[i];
      }
    }
    return false;
}

OnSipCallControl.prototype._getCallByIndex = function(index) {
  for (var i = 0; i < this.zad.calls.length; i++) {
    if (this.zad.calls[i].index == index) {
        return this.zad.calls[i];
      }
    }
    return null;
}

OnSipCallControl.prototype.shutdown = function() {
  console.log("OnSip call control shutting down");
  console.log(this);
  this.ua.stop();
}

OnSipCallControl.prototype._onMonitorCallEnd = function() {
  this.zad.monitoredAgent = null;
  this.zad.calls = []; // Needed if Barge In

  this.zad._pushStateToServer("NOT_READY");

  this.zad.emit('monitor call end');
}

OnSipCallControl.prototype._onCallEnd = function(call) {
  console.log("Running onsip onCallend...")
  callIndex = this._getCallIndex(call.sipSession.id);

  if (!callIndex)
    return;

  call.held = false;
  call.muted = false;
  call.connected = false;

  if (this.zad.calls.length == 1 && this.zad.agent.state != "RESERVED") {
    if (this.zad.agent.pendingState)
      this.zad._pushStateToServer(this.zad.agent.pendingState)
    else
      this.zad._pushStateToServer("NOT_READY")
  }

  this.zad._onCallEnd(call);
}

OnSipCallControl.prototype.answer = function(callIndex) {
  callIndex = callIndex || this._activeCallCount();
  var call = this._getCallByIndex(callIndex);

  call.sipSession.accept({
      media: {
        constraints: {
            audio: true,
            video: false
        },
        render: {
          remote: document.getElementById('remoteAudio' + callIndex.toString()),
          local: document.getElementById('localAudio'),
        }
      }
    });
}

OnSipCallControl.prototype.hangup = function(callIndex) {
  var call = this._getCallByIndex(callIndex);

  if (call.connected == true) {
     call.sipSession.bye();
  } else if (call.direction == "inbound") {
    call.sipSession.reject();
  } else if (call.direction == "outbound") {
    try {
      call.sipSession.cancel();
    } catch(err) {
      console.log("Failed to cancel, byeing instead")
      call.sipSession.bye();
    }
  }

    call.connected = false;
}

OnSipCallControl.prototype.hold = function(callIndex) {
  console.log("Running OnSip Hold");
  console.log(JSON.stringify(this.zad.calls, calls_replacer));
  var call = this._getCallByIndex(callIndex);
  call.held = true;
  call.sipSession.mute();
  this.zad._pushCallControlCommandToServer("HOLD:" + call.tropoSessionId);
}

OnSipCallControl.prototype.resume = function(callIndex) {
  var call = this._getCallByIndex(callIndex);
  call.sipSession.unmute();
  call.held = false
  call.muted = false
  this.zad._pushCallControlCommandToServer("RESUME:" + call.tropoSessionId);
  console.log("OnSip Resume");
  console.log(JSON.stringify(this.zad.calls, calls_replacer));
}

OnSipCallControl.prototype.mute = function(callIndex) {
  console.log("Running OnSip Mute");
  console.log(JSON.stringify(this.zad.calls, calls_replacer));
  var call = this._getCallByIndex(callIndex);
  call.sipSession.mute();
}

OnSipCallControl.prototype.unmute = function(callIndex) {
  console.log("Running OnSip Unmute");
  console.log(JSON.stringify(this.zad.calls, calls_replacer));
  var call = this._getCallByIndex(callIndex);
  call.sipSession.unmute();
}

OnSipCallControl.prototype.conference = function(callIndex1, callIndex2) {
  console.log("Running OnSip Conference");
  console.log(JSON.stringify(this.zad.calls, calls_replacer));
  var call1 = this._getCallByIndex(callIndex1);
  var call2 = this._getCallByIndex(callIndex2);

  this.zad._pushCallControlCommandToServer("CONFERENCE:" + call1.tropoSessionId + ":" + call2.tropoSessionId);
  this.zad.resume(callIndex1);
  call2.sipSession.bye();
}

OnSipCallControl.prototype.transfer = function(callIndex1, callIndex2) {
  console.log("Running OnSip Transfer");
  console.log(JSON.stringify(this.zad.calls, calls_replacer));
  var call1 = this._getCallByIndex(callIndex1);
  var call2 = this._getCallByIndex(callIndex2);

  this.zad._pushCallControlCommandToServer("TRANSFER:" + call1.tropoSessionId + ":" + call2.tropoSessionId);
  this.zad.resume(callIndex1);
  call2.sipSession.bye();
  call1.sipSession.bye();
}


OnSipCallControl.prototype.silentMonitor = function(agent) {
  this.zad._pushCallControlCommandToServer("MONITOR:" + agent.id);
}

OnSipCallControl.prototype.endMonitor = function() {
  console.log("OnsipCallControl: ending monitor session", this.zad.monitoredAgent);
  this.zad.monitoredAgent.sipSession.bye();
}

OnSipCallControl.prototype.bargeIn = function(agent) {
  console.log("OnsipCallControl: barging in", agent);

  this.zad._pushCallControlCommandToServer("BARGE_IN:" + agent.id);
}

OnSipCallControl.prototype.quickTransfer = function(call, number) {
  console.log("Running Onsip Quick Transfer with numer: " + number + " and call:", call);

  this.zad._pushCallControlCommandToServer("QUICK_TRANSFER:" + call.tropoSessionId + ":" + number)
  call.sipSession.bye();
}

OnSipCallControl.prototype.makeCall = function(number) {
  console.log("Running Onsip MakeCall with number: " + number);

  var to_number = number;
  try{
      to_number = phoneUtils.formatE164(number);
  } catch(err) {
    console.log("Failed to format number to e164: " + number);
  }

  console.log("Calling number: " + to_number);


  console.log(JSON.stringify(this.zad.calls, calls_replacer));
  var self = this;

  var head = "X-Call-Num:" + to_number;
  var agent = "X-Call-Agent:" + self.zad.agent.id;
  var ac = "X-Active-Call:"+ this.zad.calls.length;
  var remoteAud = 'remoteAudio' + (this.zad.calls.length + 1).toString();
  var newSession = this.ua.invite(self.env.makeCallUri, {
    'media': {
        constraints: {
            audio: true,
            video: false
        },
        render: {
          // TODO load from configuration
          remote: document.getElementById(remoteAud),
          local: document.getElementById('localAudio'),
        }
    },
    'extraHeaders': [head,agent,ac]
  });

  var callIndex = this.zad.calls.length + 1;

  var call = {
    id: newSession.id,
    index: callIndex,
    to: to_number,
    otherParty: to_number,
    direction: 'outbound',
    muted: false,
    held: false,
    connected: false,

    sipSession: newSession
  }
  this.zad.calls.push(call);
  this.zad.setCurrentCall(call.index);

    newSession.on('bye', function (session) {
      console.log('Call session', session, 'BYE');
      self._onCallEnd(call);
    });

   newSession.on('rejected', function (session1) {
     //TODO rename to call cancelled? Or consolidate with on call end
     //call_ended(newSession.id);
     console.log('Call session', session1, 'rejected');
     try {
       session1.bye();
     } catch(error) {}
     self._onCallEnd(call);
   });
   newSession.on('cancel', function (session1) {
       //call_ended(newSession.id);
      //  call.canceled = true;
      try {
        session1.bye();
      } catch(error) {}
       console.log('Call session', session1, 'canceled');
      //  self._onCallEnd(call);
   });
   newSession.on('failed', function (session1) {
       //call_ended(newSession.id);
       console.log('Call session', session1, 'failed');
       try {
         session1.bye();
       } catch(error) {}
       self._onCallEnd(call);
   });
   // TODO sip.js docs say this event handler is buggy,
   // so we're relying on others like 'bye' or 'cancel'
   // revisit maybe.
   // http://sipjs.com/api/0.6.0/session/#terminated
   // newSession.on('terminated', function (session1) {
   //     //call_ended(newSession.id);
   //     console.log('Call session', session1, 'terminated');
   //     self._onCallEnd(newSession);
   // });
    // newSession.on('accepted', function (session1) {
    //   call.connected = true;
    //   self.zad.emit('outbound call answer', call);
    // });

    this.zad.emit('outbound call begin', { session: newSession, otherParty: to_number });
    this.zad._pushStateToServer("TALKING");
    return newSession;
};

OnSipCallControl.prototype._activeCallCount = function() {
  return this.zad.calls.length;
}

function Agent(info) {
  for (var prop in info) {
    this[prop] = info[prop];
  }
}
heir.inherit(Agent, EventEmitter);

encode_into_url_params = function(obj) {
  var str = [];
  for(var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}


function calls_replacer(key, value) {
  // Filtering out properties
  if (key === "sipSession") {
    return undefined;
  }
  return value;
}

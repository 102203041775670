function renderAddressBook(){
    if(zad.agent.state === "READY" && zad.calls.length === 0) {
        zad.showAddressBook = true;
    }
    if(zad.calls.length == 1 && !zad.calls[0].connected) {
        zad.showAddressBook = false;
    }
    if(zad.calls.length == 2) {
        zad.showAddressBook = false;
    }
    if(document.getElementById('address-book-container')){
        ReactDOM.render(
            <AddressBook />,
            document.getElementById('address-book-container')
        );
    }
}

var AddressBook = React.createClass({
    getInitialState(){
        return{
            search: ""
        }
    },

    handleSearch: function(event){
        this.setState({search: event.target.value});
    },

    preventEnterFromPageExit: function(e) {
      if (e.which == 13) {
        e.preventDefault();
      }
    },

    render: function(){
        var filteredContacts = []
        var self = this;
        if(this.state.search && this.state.search.length > 0 && zad.agents && zad.agents.length > 0){
            filteredContacts = zad.agents.filter(function(entry){
                if(entry.name.slice(0, self.state.search.length).toLowerCase() === self.state.search.toLowerCase()){
                    return entry
                }
            });
        }
        else{
            filteredContacts = zad.agents;
        }
        if(zad.showAddressBook){
            return (
                    <div className="addressBook">
                        <div className="tab-content address-book" data-structure="address-book">
                            <form>
                                <div className="input-container">
                                    <input type="text" placeholder="Search" onKeyPress={this.preventEnterFromPageExit} onChange={this.handleSearch} value={this.state.search} />
                                    {SvgSearchIcon()}
                                </div>
                                <AddressBookEntries entries={filteredContacts}
                                                    search={this.state.search} />
                            </form>
                        </div>
                    </div>
                );
        } else {
            return(
                <div id="address-book"></div>
            );
        }
    }
});

var AddressBookEntries = React.createClass({

    render: function(){
        var self = this;
        var entries = this.props.entries.map(function(item, index){
            var call_address;
            var status;

            if(item.media_type === "cucm") {
                call_address = item.jtapi_call_address;
            } else if(item.media_type === "browser") {
                call_address = item.sip_uri;
            } else if(!item.media_type && item.jtapi_call_address) {
                call_address = item.jtapi_call_address;
            }

            if (item.state === "NOT_READY"){
                status = "status-light unavailable";
            } else if (item.state === "TALKING"){
                status = "status-light talking";
            } else if (item.state === "LOGOUT"){
                status = "status-light offline";
            } else {
                status = "status-light";
            }
            var entryData = {
                id: item.id,
                status: status,
                name: item.name,
                call_address: call_address,
                media_type: item.media_type,
                current_agent_state: self.props.current_agent_state
            };

            return(
                <AddressBookEntry agentData={entryData} />
            );
        });

        var self = this;
        var contacts = zad.contacts.map(function(item, index){
            var contactData = {
                id: item.id,
                status: "status-light contact",
                name: item.first_name + " " + item.last_name,
                call_address: item.call_address,
                business_phone: item.business_phone,
                home_phone: item.home_phone,
                mobile_phone: item.mobile_phone,
                other_phone: item.other_phone,
                media_type: "webrtc"
            }
            if(contactData.name.slice(0, self.props.search.length).toLowerCase() === self.props.search.toLowerCase()){
                return(
                    <AddressBookEntry agentData={contactData} />
                );
            }
        });
        return (
            <ul className="address-book-entries">
                {entries}
                {contacts}
            </ul>
        );
    }
});

class AddressBookEntry extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            contactModal: false
        }
    }

    show_number(){
        showNumberInDialPad(this.props.agentData.call_address);
    };

    make_call() {
        make_call(this.props.agentData.call_address)
    };

    quick_call(){
        if(zad.calls.length === 0) {
            make_call(this.props.agentData.call_address)
        }else {
            quick_transfer(this.props.agentData.call_address);
        }
    };

    contactModal(){
        let data = this.props.agentData;
        window.modalControls.open(ContactModal, data);
        window.modalData = data;
    };

    render(){
        var lastButtonStyle = {
            top: '-7px'
        };
        var statusLightStyle = {
            height: '8px',
            left: '8px',
            margin: '0',
            position: 'absolute',
            top: '10px',
            width: '8px'
        }
        var monitorIconStatusStyle = {
            fontSize: "12px",
            verticalAlign: "middle",
            position: "relative",
            top: "-1px"
        };
        var nameStyle = {
            paddingLeft: "20px",
            paddingRight: "50px"
        }


        var beingMonitored = zad.monitoredAgent && zad.monitoredAgent.id == this.props.agentData.id;

        if(beingMonitored) {
            lastButtonStyle = {
                verticalAlign: "middle",
                marginTop: "-13px"
            };

            statusLightStyle.left = "25px";
            nameStyle.paddingLeft = "50px";
            nameStyle.display = "inline-block";
        }

        if( zad.agent.state === "READY" || !this.props.agentData.media_type ){
            return(
                <li>
                    <div id={this.props.agentData.status} className={this.props.agentData.status}></div>
                    <div className="name">{this.props.agentData.name}</div>
                </li>
            );
        }
        else{
            return(
                <li>
                    {
                        beingMonitored ? (
                                <i style={monitorIconStatusStyle} className="fa fa-eye" aria-hidden="true"></i>
                            ) : null
                    }
                    <div style={statusLightStyle} id={this.props.agentData.status} className={this.props.agentData.status.toLowerCase()}></div>
                    <div className="name" style={nameStyle}>
                        <div className="name-text" onClick={this.quick_call.bind(this)}>{this.props.agentData.name}</div> 
                    </div>
                    <div className="control">
                        <a className="mini" onClick={this.contactModal.bind(this)}>
                            {SvgIcon({name: 'phonebook'})}
                        </a>
                        <SilentMonitorButton agentData={this.props.agentData}/>
                        {
                            !zad.monitoredAgent ? (
                                <span>
                                    <a className="mini" onClick={this.make_call.bind(this)}>
                                        {SvgIcon({name: 'transfer'})}
                                    </a>
                                    <a className="mini" onClick={this.show_number.bind(this)} style={lastButtonStyle}>
                                        <span className="text-icon" dangerouslySetInnerHTML={{__html: '&plus;'}}></span>
                                    </a>
                                </span>
                            ) : null
                        }

                    </div>
                </li>
            )
        }
    }
}

class SilentMonitorButton extends React.Component {

    constructor(props){
        super(props);
    };

    silentMonitor() {
        zad.silentMonitor(this.props.agentData);
    };

    endMonitor() {
        zad.endMonitor();
    };

    render() {
        if(!zad.agent.supervisor) {
            return null;
        }

        iconStyle = {
            fontSize: "12px",
            verticalAlign: "middle",
            top: "-1px",
            position: "relative"
        };

        iconContainerStyle = {
            marginBottom: "19px",
            textAlign: "center",
            verticalAlign: "middle"
        }

        let silentMonitorIcon = "";
        let monitorFunction = "";
        if(zad.monitoredAgent && zad.monitoredAgent.id == this.props.agentData.id) {
            silentMonitorIcon = <i style={iconStyle} className="fa fa-eye-slash" aria-hidden="true"></i>
            monitorFunction = this.endMonitor;
        } else {
            silentMonitorIcon = <i style={iconStyle} className="fa fa-eye" aria-hidden="true"></i>
            monitorFunction = this.silentMonitor;
        }

        return (
            <a style={iconContainerStyle} className="mini" onClick={monitorFunction.bind(this)}>
                {silentMonitorIcon}
            </a>
        );
    };

}

function SvgSearchIcon() {
    return <svg className="search" dangerouslySetInnerHTML={{__html: '<use xlink:href="#search"/>' }} />;
}

class ContactModal extends React.Component {
    handleClose(){
        window.modalControls.close("ContactModal");
        window.modalData = null;
    }

    render(){
        let props = this.props;
        return(
            <Modal open={true} content={<ContactModalContent {...props}/>} closeHandler={this.handleClose.bind(this)}>
            </Modal>
        )
    }
}

class ContactModalContent extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            currentStar: ""
        }
    }

    handleDial(address){
        agentConference(address);
        window.modalControls.close("ContactModal");
        window.modalData = null;
    }

    onStarEnter(index){
        this.setState({currentStar: index});
    }

    onStarLeave(index){
        this.setState({currentStar: ""});
    }

    setFavorite(address, id){
        let self = this;
        $.ajax({
            method: "POST",
            url: zad.env.apiUrl + "/api/v1/contacts/set_favorite",
            data: {id: self.props.id, address: address},
            success: function(data){
                console.log("Set Favorite, response was" + data);
            },
            error: function(err){
                alert(err);
            }
        })
    }

    render(){
        let listStyle = {
            display: 'inline-block',
        }
        let buttonStyle = {
            display: 'block',
            height: '25px',
            width: '25px',
            margin: '10px',
            borderRadius: '50%',
            backgroundColor: '#87a3bb',
            color: 'white',
            textAlign: 'center',
            paddingTop: '5px',
        }

        let numberStyle = {
            marginBottom: '10px'
        }
        let numLabelStyle = {
            marginBottom: '5px'
        }

        let starStyle = {
            height: '12px',
            width: '12px',
            marginRight: '5px'
        }

        let self = this;
        let numCount = 0;
        let numberItems = ['Business', 'Home', 'Mobile', 'Other'].map(function(title, index) {
            let starImg = 'images/unfilled-star.svg';
            if(self.state.currentStar === index || self.props[title.toLowerCase() + "_phone"] === self.props.call_address){
              starImg = 'images/star.svg';
            }
            let number = self.props[title.toLowerCase() + "_phone"];
            if(number){
                numCount++;
              return(
                <li style={numberStyle} key={index}>
                    <img onMouseEnter={self.onStarEnter.bind(self, index)}
                        onMouseLeave={self.onStarLeave.bind(self, index)}
                        onClick={self.setFavorite.bind(self, number, self.props.id)}
                        style={starStyle}
                        src={starImg}/>
                    <label style={numLabelStyle} >{title}</label><br/>
                    <a style={{color: '#0d99ff', cursor: 'pointer'}}
                      onClick={self.handleDial.bind(self, number)}>
                        {number}
                    </a>
                </li>
              )
            }
            else if(index === 3 && numCount === 0){
                return(
                  <li style={numberStyle} key={index}>
                      <img onMouseEnter={self.onStarEnter.bind(self, index)}
                          onMouseLeave={self.onStarLeave.bind(self, index)}
                          onClick={self.setFavorite.bind(self, self.props.call_address, self.props.id)}
                          style={starStyle}
                          src="images/star.svg"/>
                      <label style={numLabelStyle} >Zomnio Phone</label><br/>
                      <a style={{color: '#0d99ff', cursor: 'pointer'}}
                        onClick={self.handleDial.bind(self, self.props.call_address)}>
                          {self.props.call_address}
                      </a>
                  </li>
                )
            }
        });

        return(
            <div style={{width: '100%'}}>
                <h1 style={{paddingBottom: '10px', borderBottom: '1px solid lightGrey', textAlign: 'center'}}>
                    { this.props.name }
                </h1>
                {/* <ul style={{textAlign: 'center'}}>
                    <li style={listStyle}>
                    <span style={buttonStyle}>Q</span>
                    </li>
                    <li style={listStyle}>
                    <span style={buttonStyle}>T</span>
                    </li>
                    </ul> */}

                <br/>
                <ul style={{marginTop: '10px'}}>
                    {numberItems}
                </ul>
            </div>
        )
    }
}

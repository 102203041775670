function renderReact(currentCall) {

  if (!zad || !zad.agent) {
    return;
  }

  var line = currentCall ? currentCall.index : 1

  ReactDOM.render(
    <CallControls currentCall={currentCall}/>,
    document.getElementById('call-controls-line-' + line)
  );

}

function CallControls(props) {
   
  if(zad.monitoredAgent) {
    return MonitorCallControls(props);
  }

  if(!props.currentCall || zad.calls.length == 0) {
      noCallsStyle = {display: 'none'};
      return <div className="call-control-controls-react" style={noCallsStyle}></div>
  }

  if(zad.mediaType == "cucm") {
    return CucmCallControls(props);
  } else {
    return BrowserCallControls(props);
  }

}

function MonitorCallControls(props) {
  return  <div className="call-control-controls-react">
            <EndMonitorButton />
            <BargeInButton />
          </div>
}

function CucmCallControls(props) {

  if(props.currentCall.index == 1) {
    return CucmTabOneCallControls(props);
  } else {
    return CucmTabTwoCallControls(props);
  }

}

function CucmTabOneCallControls(props) {
  var currentCall = props.currentCall;
  if (currentCall.held) {
     return <div className="call-control-controls-react">
              <HoldButton call={currentCall} line={currentCall.index} />
            </div>
  } else if (!currentCall.held && currentCall.connected) {
    return <div className="call-control-controls-react">
              <HoldButton call={currentCall} line={currentCall.index} />
              <HangupButton line={currentCall.index} />
            </div>
  } else if (!currentCall.held && !currentCall.connected) {
    return <div className="call-control-controls-react">
              <HangupButton line={currentCall.index} />
            </div>
  } 
}

function CucmTabTwoCallControls(props) {
  var currentCall = props.currentCall;
  if (currentCall.held) {
    return <div className="call-control-controls-react">
              <HoldButton call={currentCall} line={currentCall.index} />
            </div>
  } else if (!currentCall.held && currentCall.connected) {
    var otherCall = getCallByIndex(1);
    if(otherCall.held && currentCall.direction == "outbound") {
      return <div className="call-control-controls-react">
              <HoldButton call={currentCall} line={currentCall.index} />
              <ConferenceButton line={currentCall.index} label={"Conf"} />
              <TransferButton line={currentCall.index} />
              <HangupButton line={currentCall.index} />
            </div>
    } else {
      return <div className="call-control-controls-react">
              <HoldButton call={currentCall} line={currentCall.index} />
              <HangupButton line={currentCall.index} />
            </div>
    }
  } else if (!currentCall.held && !currentCall.connected) {
     return <div className="call-control-controls-react">
              <HangupButton line={currentCall.index} />
            </div>
  }
}

function BrowserCallControls(props) {
  var currentCall = props.currentCall;
  if(currentCall.index == 1) {
    return BrowserTabOneCallControls(props);
  } else {
    return BrowserTabTwoCallControls(props);
  }
}

function BrowserTabOneCallControls(props) {
  var currentCall = props.currentCall;
  if(currentCall.connected) {
    return <div className="call-control-controls-react">
      <HoldButton call={currentCall} line={currentCall.index} />
      <MuteButton call={currentCall} line={currentCall.index} />
      <HangupButton line={currentCall.index} />
    </div>
  } else {
    return <div className="call-control-controls-react">
      <HangupButton line={currentCall.index} />
    </div>
  }
  
}

function BrowserTabTwoCallControls(props) {
  var currentCall = props.currentCall;
  if(currentCall.connected && currentCall.direction == "outbound") {
    return <div className="call-control-controls-react">
      <HoldButton call={currentCall} line={currentCall.index} />
      <MuteButton call={currentCall} line={currentCall.index} />
      <HangupButton line={currentCall.index} />
      <ConferenceButton line={currentCall.index} />
      <TransferButton line={currentCall.index} />
    </div>
  } else if(currentCall.connected && currentCall.direction == "inbound") {
    return <div className="call-control-controls-react">
      <HoldButton call={currentCall} line={currentCall.index} />
      <MuteButton call={currentCall} line={currentCall.index} />
      <HangupButton line={currentCall.index} />
    </div>
  } else {
    return <div className="call-control-controls-react">
      <HangupButton line={currentCall.index} />
    </div>
  }
}

function EndMonitorButton(props) {
  var iconStyle = {
    color: "white",
    marginTop: "10px"
  }

  return <a className="round-button" id={"end-monitor-but"} onClick={endMonitor.bind(null)}>
    <span className={"icon-container end-monitor"}>
      <i className="fa fa-eye-slash" aria-hidden="true" style={iconStyle}></i>
    </span>
    <span id={"end-monitor-label"} className="label">{"End Monitor"}</span>
  </a>
}

function BargeInButton(props) {
  var iconStyle = {
    display: "block",
    fill: "#fff",
    height: "22px",
    margin: "8px auto",
    width: "22px"
  }

  if(zad.monitoredAgent && zad.monitoredAgent.state.toLowerCase() == "talking") {

    return <a className="round-button" id={"barge-in-but"} onClick={bargeIn.bind(null)}>
      <span className={"icon-container barge-in"}>
        <svg style={iconStyle} dangerouslySetInnerHTML={{__html: '<use xlink:href="#call_merge"/>' }} />
      </span>
      <span id={"barge-in-label"} className="label">{"Barge In"}</span>
    </a>

  }

  return null;
}

function HoldButton(props) {
  var call = props.call; 
  var label;
  if(call.held) {
    label = "Resume";
  } else {
    label = "Hold";
  }
  return <CallControlButton label={label} type="hold" function={hold.bind(null, props.line)} icon="pause" line={props.line}/>
}
function MuteButton(props) {
  var call = props.call; 
  var label;
  if(call.muted) {
    label = "Unmute";
  } else {
    label = "Mute";
  }
  return <CallControlButton label={label} type="mute" function={mute.bind(null, props.line)} icon="mute" line={props.line}/>
}
function HangupButton(props) {
  return <CallControlButton label="End" type="hangup" function={hangup.bind(null, props.line)} icon="end" line={props.line}/>
}
function ConferenceButton(props) {
  var label;
  if(props.label) {
    label =props.label;
  } else {
    label = "Conference"
  }
  
  return <CallControlButton label={label} type="conference" function={conference.bind(null)} icon="transfer" line={props.line}/>
}
function TransferButton(props) {
  return <CallControlButton label="Transfer" type="transfer" function={transfer.bind(null)} icon="transfer" line={props.line}/>
}


function CallControlButton(props) {
  return <a className="round-button" id={props.type + "-but"} onClick={props.function}>
    <span className={"icon-container " + props.type}>
      <SvgIcon name={props.icon} />
    </span>
    <span id={props.type + "-" + props.line + "-label"} className="label">{props.label}</span>
  </a>
}

function SvgIcon(props) {
  return <svg dangerouslySetInnerHTML={{__html: '<use xlink:href="#' + props.name + '"/>' }} />;
}

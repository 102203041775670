$(document).ready(function() {
  $("[data-behavior='switch-tab']").click(function(e) {
    console.log("Tab was switched");
    e.preventDefault();

    var tab = $(this).attr("data-target"),
    target = $("[data-structure='"+ tab +"']")
    parent = $(this).parent();

    if(tab == "address-book"){
        zad.showAddressBook = true;
        renderAddressBook();
    }
    else{
        zad.showAddressBook = false;
        renderAddressBook();
    }

    parent.addClass("current");
    parent.siblings().removeClass("current");
    parent.parent().siblings(".tab").children(".tab-content").hide();
    $(target).fadeIn();

    var index = $(this).attr("data-index");
    if(zad && index) {
        console.log("Setting current call to " + index);
        zad.setCurrentCall(index);
        render();
    }

  });
});

var ReactCSSTransitionGroup = React.addons.CSSTransitionGroup;

window.modalControls = {
    open:  function(type, props) {
        let container = document.getElementById('modal');
        let options = props || {};

        options.open = true;
        ReactDOM.render(React.createElement(type, options), container);

    },

    close: function(type, props) {
        let container = document.getElementById('modal');
        let options = props || {};

        options.open = false;
        ReactDOM.render(React.createElement(type, options), container);
    }
}

class Modal extends React.Component {

    handleClose() {
        if (this.props.closeHandler) {
            this.props.closeHandler();
        }
    }

    handleCurtainClick() {
        // Default to closing on curtain click.
        if (this.props.closeOnCurtainClick === false) {
            return;
        }

        this.handleClose();
    }

    render() {
        return (
            <ReactCSSTransitionGroup
              transitionName="modal"
              transitionAppear={true}
              transitionEnter={true}
              transitionLeave={true}
              transitionAppearTimeout={200}
              transitionEnterTimeout={200}
              transitionLeaveTimeout={200}>

              {this.props.open ?
                <div id="shown" key="shown">

                    <div className="curtain" style={{display: 'block'}} onClick={this.handleCurtainClick.bind(this)}></div>
                    <div role="dialog"
                        aria-labelledby={"modal-header-" + this.props.name}
                        aria-describedby={"modal-content-" + this.props.name}
                        className="modal"
                        style={{display: 'block'}}
                    >

                    {this.props.showClose !== false ?
                      <a role="button" className="close" onClick={this.handleClose.bind(this)}>
                        <span aria-hidden="true">&times;</span>
                        <span className="sr-only">Close</span>
                      </a>
                    :
                        ''
                    }
                      <ModalHeader title={this.props.title} name={this.props.name} />

                      <ModalContent name={this.props.name}>
                        {this.props.content}
                      </ModalContent>

                    </div>
                </div>
                :
                ''
            }
            </ReactCSSTransitionGroup>
        )
    }
}

class ModalHeader extends React.Component {
    render() {
        return (
              <div className="modal-header" id={"modal-header-" + this.props.name}>
                <span className="label">{this.props.title}</span>
              </div>

        )
    }
}

class ModalContent extends React.Component {
    render() {
        return (
              <div id={"modal-content-" + this.props.name}>
                <div className="modal-body">
                    { this.props.children }
                </div>
              </div>

        )
    }
}

RECONNECT_TIME_LIMIT_SECONDS = 15;
class DisconnectedModal extends React.Component {

    componentWillMount() {
        this.reset();
    }

    reset() {
        this.setState({
            elapsed: false,
            closing: false
        });
    }

    needsReset() {
        return (this.state.elapsed || this.state.closing);
    }

    reconnectTimedOut() {
        console.log('Reconnect time limit elapsed!');

        this.setState({
            elapsed: true
        });

        setTimeout(this.disconnect, 1000);
    }

    cancelReconnect() {
        this.setState({
            closing: true
        })

        setTimeout(this.disconnect, 500);
    }

    disconnect() {
        zad.logout();

        setTimeout(() => {
            zad._disconnect();
        }, 500);
    }

    render() {
        if (!this.props.open && this.needsReset()) {
            setTimeout(this.reset.bind(this), 200);
        }

        let timerContent = (
            <div style={{minHeight: '120px'}}>
                <p>Lost connection to Zomnio, trying to reconnect.</p>
                <p>Trying for another ...


                    <span className="reconnect-timer">
                        <CountdownTimer
                            elapsedCallback={this.reconnectTimedOut.bind(this)}
                            timeLimitSeconds={RECONNECT_TIME_LIMIT_SECONDS}
                        />
                    </span>
                </p>

                <div style={{
                    position: 'absolute',
                    bottom: '30px',
                    width: 'calc(100% - 60px)'
                }}>
                    <button className="centered error-button" onClick={this.cancelReconnect.bind(this)}>Logout Now</button>
                </div>
            </div>
        );

        let elapsedContent = (
            <div style={{minHeight: '120px'}}>
                <p>Failed to reconnect.</p>
                <p style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    paddingTop: '30px'
                }}>
                    <span className="reconnect-timer">Logging out ...</span>
                </p>
            </div>
        );

        return (
            <Modal open={this.props.open} name="reconnect-modal" title="Disconnected"
                closeOnCurtainClick={false}
                showClose={false}
                resetHandler={this.reset.bind(this)}
                closeHandler={this.cancelReconnect.bind(this)}
                content={ !this.state.elapsed && !this.state.closing ? timerContent : elapsedContent }
            />
        );
    }
}


var callTimer;
var ringingOut = false;
var ringbackTimer;


var zad;

$(document).ready(function() {
  if (window.location.protocol != "https:" &&
      (window.location.host == "agent.zomnio.com" || window.location.host == "agent-staging.zomnio.com" || window.location.host == "agent.zomn.io")) {
    window.location.href = "https:" + window.location.href.substring(window.location.protocol.length);
  }
  $('login_form').on('submit', function(e){
    // validation code here
    if(!valid) {
      e.preventDefault();
    }
  });
});



function init() {
  zad = new Zad();
  zad.on('login', completeLogin);
  $("login").className = "";

  makeCallOnEnter();
}

function getStateDisplay(state) {
  switch (state) {
      case 'LOGOUT':
        return 'Not Ready';
      case 'READY':
          return 'Ready';
      case 'NOT_READY':
          return 'Not Ready';
      case 'TALKING':
          return 'Talking';
      case 'RESERVED':
        return 'Reserved';
      case 'RINGING':
        return 'Ringing'
      case 'OFFHOOK':
        return 'Offhook';
      default:
          return state;
  }
}

function login() {
  var username = $("#username").val();
  var password = $("#password").val();
  var ext = $("#ext").val();
  var mediaType = document.getElementById('media').value

  zad.login({
    username: username,
    password: password,
    extension: ext,
    mediaType: mediaType
  }, function (err, agent) {
    if (err)
      return console.error("Login failed!", err);

  });
}

function add_digit(num){
    var current_num = $('#dial_num').val();
    $('#dial_num').val(current_num + num);
};

function completeLogin(agent) {
  console.log("Login Succeeded!", agent);

  $('#login_div').css('display', 'none');
  $("body").removeAttr("class");
  $('.app, .primary').show();

  $('[data-structure="user-image"]').attr("data-user-email", agent.email);
  var userImage = $("[data-structure='user-image']");
  var email = userImage.attr("data-user-email");
  var md5email = $.md5(email);
  var gravatar = 'https://www.gravatar.com/avatar/' + md5email + '?s=200';
  userImage.css('background-image', 'url(' + gravatar + ')');
  $('[data-structure="user-name"]').html(agent.name);
  $('.login-name').html(agent.name);

  window.onbeforeunload = function() {
    return "By leaving this page we will automatically log you out of the system and you will need to log back in.  Are you sure you want to do this?"
  }

  function unhook() {
    window.onbeforeunload = null;
  }

  $(window).unload(function() {
    if (zad) {
      zad.closeClient();
    }
  });

  zad.on('team member change', handleTeamMemberStateChange);
  zad.on('team member remove', handleTeamMemberRemove);

  zad.on('agent change', handleStateChange);
  zad.on('pending state change', handlePendingStateChange);

  zad.on('logout', agentLoggedOut);
  zad.on('outbound call begin', handleOutboundCallBegin);
  zad.on('phone offhook', handlePhoneOffhook);
  zad.on('outbound call answer', handleOutboundCallAnswer);
  zad.on('call end', handleCallEnd);
  zad.on('inbound call begin', handleInboundCallBegin);
  zad.on('inbound call answer', handleInboundCallAnswer);
  zad.on('call data update', handleCallDataUpdate);
  zad.on('call update', handleCallUpdate);

  zad.on('stats update', handleStatsUpdate);
  zad.on('stats personal update', handleStatsPersonalUpdate);


  zad.on('monitor begin', handleSilentMonitorBegin);
  zad.on('monitor end', handleMonitorEnd);

  zad.on('disconnected', handleDisconnectedEvent);
  zad.on('reconnected', handleReconnectedEvent);

  startTimer();
}

function hideMakeCall(){
  $('.make-call-tab').hide();
  $('.address-book-tab').attr("class","address-book-tab current");
  $('.make-call-tab').attr("class","make-call-tab");
  $(".make-call").hide();
  $(".address-book").fadeIn();
}
function showMakeCall(){
  $('.make-call-tab').show();
  $('.make-call-tab').attr("class","make-call-tab current");
  $('.address-book-tab').attr("class","address-book-tab");
  $(".address-book").hide();
  $(".make-call").fadeIn();
}
function enableMakeCall(){
  $('.make-call-tab').show();
}
function showCallTab(call_id) {
  $('#tab-' + call_id + '-display').show();
  $('#tab-' + call_id + '-display').attr("class","current")
  $('#tab-' + call_id + '-a').attr("class","active-call");
  if (call_id == 1){
    $('#tab-2').hide();
  } else {
    $('#tab-1').hide();
    $('#tab-1-display').attr("class","current")
  }
  $('#tab-' + call_id).show();
  $('#tab-' + call_id).fadeIn();
}
function hideCallTab(call_id){
  $('#tab-' + call_id + '-display').hide();
  $('#tab-' + call_id).hide();
  $('#tab-1').show();
  $('#tab-1').fadeIn();
}
function showCallControls() {
  if(zad.mediaType == "cucm") {
    $('.jtapi-call-control-controls').show();
    $('.jtapi-call-control > .tab').addClass('controls-shown');
  } else {
    $('.call-control-controls').show();
    $('.call-control > .tab').addClass('controls-shown');
  }
}
function hideCallControls() {
  if(zad.mediaType == "cucm") {
    $('.jtapi-call-control-controls').hide();
    $('.jtapi-call-control > .tab').removeClass('controls-shown');
  } else {
    $('.call-control-controls').hide();
    $('.call-control > .tab').removeClass('controls-shown');
  }
}



function render() {
  console.log("Rendering...");

  renderTimers();
  renderReact(getCurrentCall());
  renderMakeCall();
  renderHeader();
  renderCallTabs();
  renderIcons();
  renderMenuTabs();
  renderAddressBook();
}


function renderMenuTabs() {
  if(zad.calls.length > 0) {
    $('.make-call-tab span').text("Add A Call");
  } else {
    $('.make-call-tab span').text("Make A Call");
  }
}

function renderMakeCall() {
  if(zad.calls.length == 2) {
    hideMakeCall()
  } else if(zad.mediaType == "cucm" && zad.calls.length == 1 && zad.calls[0].held) {
    hideMakeCall();
  } else if(zad.calls.length == 1 && !zad.calls[0].connected) {
    hideMakeCall();
  } else if(zad.calls.length == 1) {
    showMakeCall();
  } else if (zad.calls.length == 0 && zad.agent.state == "NOT_READY" && !zad.showAddressBook) {
    showMakeCall();
  } else if (zad.calls.length == 0 && zad.agent.state == "NOT_READY" && zad.showAddressBook) {
    enableMakeCall();
  } else {
    hideMakeCall();
  }
}

// WARNING: The 'current' attribute of call is not yet properly updated for onSIP!!!!
function renderHeader() {
  console.log("Updating header");

  if(zad.monitoredAgent) {
    $('.call-notification').show();

    $('#number-1').html(zad.monitoredAgent.name);
    $('#number-1').show();
    $('#number-2').hide();

    $('.answer-button').hide();

    $('.not-lang').html("Monitoring :");

    return;
  }


  if(zad.calls.length == 0) {
    $('.call-notification').hide();
  } else {
    $('.call-notification').show();
  }
  if(zad.calls.length == 1) {
    $('#number-2').html("");
    $('#number-2').hide();
  }
  for(var i = 0 ; i < zad.calls.length; i++) {
    var call = zad.calls[i];
    if(call.current && call.otherParty) {
      $('#number-'+ call.index).html(call.otherParty);
      $('#number-'+ call.index).show();
    } else {
      $('#number-'+ call.index).html("");
      $('#number-'+ call.index).hide();
    }
  }

  var currentCall = getCurrentCall();


  if(!currentCall) {
    $('.answer-button').hide();
  } else if(currentCall.connected) {
    $('.not-lang').html("Connected To :");
    $('.answer-button').hide();
  } else if(currentCall.direction == "inbound") {
    $('.not-lang').html("Incoming Call From :");
    $('.answer-button').show();
  } else if(currentCall.otherParty) {
    $('.not-lang').html("Connecting To :");
    $('.caller-info-number').html(currentCall.otherParty);
    $('.answer-button').hide();
  } else {
    $('.not-lang').html("Offhook");
    $('.answer-button').hide();
  }

}

function renderCallTabs() {
  console.log("Updating rendered Call Tabs");
  var currentCall = getCurrentCall();
  if(currentCall) {
    showCallTab(currentCall.index);
  } else {
    showCallTab(1);
  }
  if(zad.calls.length == 2) {
    $('#tab-2-display').show();
  } else {
    $('#tab-2-display').hide();
  }
}

// WARNING: The 'current' attribute of call is not yet properly updated for onSIP!!!!
function renderIcons() {
  console.log("Updating rendered icons");
  for(var i = 0 ; i < zad.calls.length; i++) {
    var call = zad.calls[i];
    if(call.held) {
      console.log("call " + call.index + " is held -- updating icon to pause");
      $("#tab-" + call.index + "-icon").html('<use xlink:href="#pause" />');
    } else {
      console.log("call " + call.index + " is not held -- updating icon to phone2");
      $("#tab-" + call.index + "-icon").html('<use xlink:href="#phone2" />');
    }
    if(call.current) {
      console.log("call " + call.index + " is current -- making active");
      $("#tab-" + call.index + "-a").addClass("active-call");
      $("#tab-" + call.index + "-a").removeClass("hold-call");
    } else {
      console.log("call " + call.index + " is not current -- making inactive");
      $("#tab-" + call.index + "-a").removeClass("active-call");
      if(call.held) {
        $("#tab-" + call.index + "-a").addClass("hold-call");
      } else {
        $("#tab-" + call.index + "-a").removeClass("hold-call");
      }
    }
  }
  if(zad.calls.length == 0) {
    $("#tab-1-icon").html('<use xlink:href="#phone2" />');
    $("#tab-1-a").addClass("active-call");
  }
  console.log("Done updating rendered icons");
}


function renderTimers() {
  console.log("Updating rendered timers");

  if(zad.monitoredAgent) {
    $("#time-1").show();
    $("#time-2").hide();

    return;
  }

  for(var i = 0 ; i < zad.calls.length; i++) {
    var call = zad.calls[i];
    if(call.current && call.connected) {
      console.log("Call " + call.index + " is current. Showing timer");
      $("#time-" + call.index).show();
    } else {
      console.log("Call " + call.index + " is not current and connected. Hiding timer");
      $("#time-" + call.index).hide();
    }
  }

  if(zad.calls.length == 1) {
    $("#time-2").hide();
    $(".tab-2-header").html("No Active Call");
  }

  if(zad.calls.length == 0) {
    $("#time-1").hide();
    $("#time-2").hide();
    $(".tab-1-header").html("No Active Call");
    $(".tab-2-header").html("No Active Call");
  }

}


function ready() {
  zad.ready();
}

function not_ready() {
  zad.notReady();
}

function logout(){
  zad.logout();
  clearInterval(callTimer);
}

function agentLoggedOut() {
  modalControls.close(DisconnectedModal);

  $('#login_div').show();
  $("body").attr("class","login");
  $('.app, .primary').hide();
  //handleStateChange("Not Ready");
  document.getElementById("login_form").style.display = "block";
}


function handleStatsPersonalUpdate(stats) {
  console.log("Handling stats personal update");

  var stat_list = '<li>' +
                '<div class="label">People on hold</div>' +
                '<div class="metric stat-coh">' + stats.coh + '</div>' +
                '</li>' +
                '<li>' +
                '<div class="label">Calls handled today</div>' +
                '<div class="metric stat-cht">' + stats.cht + '</div>' +
                '</li>' +
                '<li>' +
                '<div class="label">Average call time</div>' +
                '<div class="metric stat-act">' + stats.act + '</div>' +
                '</li>' +
                '<li>' +
                '<div class="label">Current longest hold time</div>' +
                '<div class="metric stat-lht">' + stats.lht + '</div>' +
                '</li>';
  $('#personal-stats').empty();
  $('#personal-stats').append('<h3 style="border-top: 1px solid lightGrey; border-bottom: 1px solid lightGrey; margin-bottom: 10px;">Personal</h3>');
  $('#personal-stats').append(stat_list);
}


function handleStatsUpdate(stats, skills){
    $('.skills-stats').empty();

    $.each(skills, function(skillKey, skillValue){
        var stat_list = '<li>' +
                '<div class="label">People on hold</div>' +
                '<div class="metric stat-coh" data-id="' + skillKey + '">Loading...</div>' +
                '</li>' +
                '<li>' +
                '<div class="label">Calls handled today</div>' +
                '<div class="metric stat-cht" data-id="' + skillKey + '">Loading...</div>' +
                '</li>' +
                '<li>' +
                '<div class="label">Average call time</div>' +
                '<div class="metric stat-act" data-id="' + skillKey + '">Loading...</div>' +
                '</li>' +
                '<li>' +
                '<div class="label">Current longest hold time</div>' +
                '<div class="metric stat-lht" data-id="' + skillKey + '">Loading...</div>' +
                '</li>';
        $('.skills-stats').append('<h3 style="border-top: 1px solid lightGrey; border-bottom: 1px solid lightGrey; margin-bottom: 10px;">' + skills[skillKey] + '</h3>');
        $('.skills-stats').append(stat_list);
        $('.stat-coh[data-id=' + skillKey +']').text(stats[skillKey].coh);
        $('.stat-cht[data-id=' + skillKey +']').text(stats[skillKey].cht);
        $('.stat-act[data-id=' + skillKey +']').text(stats[skillKey].act);
        $('.stat-lht[data-id=' + skillKey +']').text(stats[skillKey].lht);
    });
}

function handleCallEnd(call) {
  console.log("Handling Call End of Call: " + call);

  stopRingingIn();
  stopRingingOut();

  render();
}

function handleSilentMonitorBegin() {
  console.log("Handling silent monitor begin of agent " + zad.monitoredAgent.id);

  render();
}

function handleMonitorEnd() {
  console.log("Handling monitor end");

  render();
}


function handleOutboundCallAnswer(call) {
  console.log("Handling outbound call answer");

  stopRingingOut();
  render();
}

function handleOutboundCallBegin(call) {
  console.log("Handling outbound call begin");

  startRingingOut();
  render();
}

function handlePhoneOffhook(call) {
  console.log("Handling phone offhook");

  render();
}


function handleInboundCallBegin(call) {
  console.log("Handling inbound call begin");

  startRingingIn();

  render();
}

function handleInboundCallAnswer(call) {
  console.log("Running handleInboundCallAnswer");

  stopRingingIn();

  if (call.data != null && call.data.screen_pop_url) {
      console.log("opening...");
      window.open(call.data.screen_pop_url,'zscreenpop')
  }

  if(zad.mediaType == "cucm" && call.index == 2) {
    zad.calls[0].held = true;
  }

  render();
}



function quick_transfer(number) {
  var call = getCallByIndex(1);
  zad.quickTransfer(call, number);
}


function make_call(number){
    if(number){
        var dial = number;
    }
    else{
        dial = $("#dial_num").val();
    }
    var call = getCallByIndex(1);
    if(call && !call.held && zad.mediaType != "cucm"){
        hold(1);
    }
    return zad.makeCall(dial);
}


function hold(callIndex){
  console.log("Toggling Hold for callIndex: " + callIndex + " in softphone");
  var call = getCallByIndex(callIndex);

  if (call.held) {
    resume(callIndex);
  } else {
    console.log("Holding callIndex: " + callIndex + " in softphone");
    zad.hold(callIndex);
  }

  render();
}


function resume(callIndex){
  console.log("Resuming callIndex: " + callIndex + " in softphone");
  zad.resume(callIndex);

  if(zad.mediaType == "cucm" && zad.calls.length > 1) {
    var otherIndex = 2 - callIndex + 1; // 1 will become 2, and 2 will become 1 :)
    getCallByIndex(otherIndex).held = true;
  } else if(zad.calls.length > 1) {
    var otherIndex = 2 - callIndex + 1;
    hold(otherIndex);
  }

}

function hangup(callIndex){

  if (callIndex == 2) {
    hideCallTab(2);
  }

  zad.hangup(callIndex);
}

function answer() {
  $('.answer-button').hide();
  zad.answer(getCurrentCall().index);
}

function conference(){
  zad.conference(1, 2);

  hideCallTab(2);
  showCallTab(1);

  if(zad.calls[0]) {
    zad.calls[0].held = false;
  }
}

function transfer(){
  zad.transfer(1, 2);

  hideCallTab(2);
  showCallTab(1);
}

function mute(callIndex){
  console.log("muting");
  var call = getCallByIndex(callIndex);

  if (!call.muted){
    zad.mute(callIndex);
  } else {
    unmute(callIndex);
  }

  render();
}

function unmute(callIndex){
  zad.unmute(callIndex)
}

function endMonitor() {
  zad.endMonitor();
}

function bargeIn() {
  zad.bargeIn();
}

function handleCallDataUpdate(call) {
  $('#number-' + zad.calls.length).html(call.otherParty);

  $(".call-info-section").html("<h3>Caller Input Information</h3>");
  for (key in call.data) {
    $(".call-info-section").append("<div><div class=\"label\">" + key + "</div><div class=\"info\">" + call.data[key] + "</div></div>");
  }

  $('.call-info').show();
}

function handleCallUpdate() {
  console.log('Handling call update...');
  render();
}

// Used to track status of other agents in org
function handleTeamMemberStateChange()
{
    console.log("Handling team member state change");
    render();
    if(window.modalData){
        var newModalData = window.modalData;
        for(var i = 0; i < zad.contacts.length; i++){
            if(zad.contacts[i].id === window.modalData.id){
                newModalData.call_address = zad.contacts[i].call_address;
            }
        }
        window.modalControls.open(ContactModal, newModalData);
    }
}
function handleTeamMemberRemove(agent_id){
    agent_doc = $("#" + agent_id + "-address");
    agent_doc.remove();
}

function showNumberInDialPad(dial){
  showMakeCall();
  $('#dial_num').val(dial);
}

function handleStateChange(agent){
  console.log("Handling state change");
  $(".status").html(getStateDisplay(agent.state));
  if(agent.state=="READY"){
      $("#current-status").removeAttr('class');
      $("#current-status").addClass('available status-light');
      statusSelector = $("[data-structure='status-selector']");
      statusSelector.hide();
      setTimeout(function() {statusSelector.show();}, 500);
      $(".status-ready").hide();
      $(".status-not-ready").show();
  } else if(agent.state=="NOT_READY"){
      $("#current-status").removeAttr('class');
      $("#current-status").addClass('unavailable status-light');
      statusSelector = $("[data-structure='status-selector']");
      statusSelector.hide();
      setTimeout(function() {statusSelector.show();}, 500);
      $(".status-not-ready").hide();
      $(".status-ready").show();
  } else if(agent.state=="RESERVED"){
      $("#current-status").removeAttr('class');
      $("#current-status").addClass('unavailable status-light');
  } else if(agent.state=="TALKING"){
      $("#current-status").removeAttr('class');
      $("#current-status").addClass('talking status-light');
  } else if(agent.state=="RINGING") {
      $("#current-status").removeAttr('class');
      $("#current-status").addClass('unavailable status-light');
  }

  render();
 }

function handlePendingStateChange(agent) {
  if(agent.pendingState == "READY") {
      statusSelector = $("[data-structure='status-selector']");
      statusSelector.hide();
      setTimeout(function() {statusSelector.show();}, 500);
      $(".status-ready").hide();
      $(".status-not-ready").show();
  } else if(agent.pendingState == "NOT_READY"){
      statusSelector = $("[data-structure='status-selector']");
      statusSelector.hide();
      setTimeout(function() {statusSelector.show();}, 500);
      $(".status-not-ready").hide();
      $(".status-ready").show();
  }
}

function stopRingingOut() {
  $('#ringback').trigger('pause');
  $('#ringback').prop('currentTime', 0);
  ringingOut = false;
}

function startRingingOut() {
  if(zad.mediaType === "cucm") {
    return;
  }
  ringingOut = true;
  clearTimeout(ringbackTimer);
  ringbackTimer = setTimeout(function () {
    if (ringingOut) {
      $('#ringback').trigger('play')
    }
  }, 1000);
}

function stopRingingIn() {
  $('#ringtone').trigger('pause');
}
function startRingingIn() {
  if(zad.mediaType !== "cucm") {
    $('#ringtone').trigger('play');
  }
}

function getCurrentCall() {
  for(var i = 0; i < zad.calls.length; i++) {
    var call = zad.calls[i];
    if(call.current) {
      return call;
    }
  }
  return null;
}

function startTimer() {

  function pad(val) {
    return val > 9 ? val : "0" + val;
  }

  callTimer = setInterval(function () {

    if(zad.monitoredAgent) {
      var sec = Math.floor( (new Date() - zad.monitoredAgent.startTime) / 1000 );
      var time = pad(Math.floor(sec / 60)) + ":" + pad(sec % 60)
      $("#time-1").html(time);
      $(".tab-1-header").html(zad.monitoredAgent.name + " - " + time);
      return;
    }

    if(zad.calls.length == 0) {
      $("#time-1").html("00:00");
      $(".tab-1-header").html("No Active Call");
      return;
    }

    for(var i = 0; i < zad.calls.length; i++) {
      var call = zad.calls[i];

      if(!call.connected) {
        continue;
      }

      if(!call.startTime) {
        call.startTime = new Date();
      }

      var sec = Math.floor( (new Date() - call.startTime) / 1000 );
      var time = pad(Math.floor(sec / 60)) + ":" + pad(sec % 60)


      $("#time-" + call.index).html(time);
      if(call.otherParty) {
        $(".tab-" + call.index + "-header").html(call.otherParty + " - " + time);
      } else {
        $(".tab-" + call.index + "-header").html("Offhook - " + time);
      }
    }

  }, 100);

}

function getCallByIndex(index){
  for (var i = 0; i < zad.calls.length; i++) {
    if (zad.calls[i].index == index){
      return zad.calls[i];
    }
  }

  return null;
}

function handleDisconnectedEvent() {
  console.warn('Zad disconnected.');
  modalControls.open(DisconnectedModal);
}

function handleReconnectedEvent() {
  console.log('Zad connected.');
  modalControls.close(DisconnectedModal);
}

function makeCallOnEnter() {
  $(document).keypress( function(e) {

    if(!zad.agent) {
      return;
    }

    preventEnterFromPageExit(e);

    if ( $(".make-call-tab").length > 0 ) {
      var isCallTabOpen = $(".make-call-tab").attr("class").includes("current")
      var isDialNotEmpty = $("#dial_num").val().length > 1

      if (e.which ==  13 && isCallTabOpen && isDialNotEmpty){
          make_call();
      };
    }

  });
}

function preventEnterFromPageExit(e) {
  if (e.which == 13){
    e.preventDefault();
  }
}

class CountdownTimer extends React.Component {

    componentWillMount() {
        this.setState({
            startedAt: new Date(),
            timeLeft: this.getTimeLimitInMillis()
        });
    }

    componentDidMount() {
        this.timer = setInterval(() => {
            let now = new Date();
            let timeElapsed = now - this.state.startedAt;
            let timeLeft = this.getTimeLimitInMillis() - timeElapsed;
            if (timeLeft < 0) {
                timeLeft = 0;
            }

            this.setState({
                timeLeft: timeLeft
            });

            if (timeLeft < 1000 && this.props.elapsedCallback) {
                this.props.elapsedCallback();
            }
        }, 100);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    getTimeLimitInMillis() {
        // Pad slightly so the initial time in minutes shows for about a second.
        return (this.props.timeLimitSeconds * 1000) + 900
    }

    render() {
        let timeLeft = this.state.timeLeft / 1000;
        let minutes = Math.floor(timeLeft / 60);
        let seconds = Math.floor(timeLeft % 60);

        let minuteText = "";
        if (minutes > 1) {
            minuteText = minutes + " minutes";
        } else if (minutes === 1) {
            minuteText = minutes + " minute";
        }

        let secondText = "";
        if (seconds > 1) {
            secondText = seconds + " seconds";
        } else if (seconds == 1) {
            secondText = seconds + " second";
        }

        return (
            <span>{minuteText} {secondText}</span>
        )
    }
}
